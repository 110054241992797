import styled from "styled-components";

const OutlinedButton = styled.button`
    text-align: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 1rem 1.25rem;
    cursor: pointer;

    &:hover {
        background-color: black;
        color: white;
    }
`;

const Button = styled.button`
    text-align: center;
    background-color: black;
    border: 1px solid black;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 1rem 1.25rem;
    cursor: pointer;
    color: white;

    &:hover {
        opacity: .75;
    }
`;

export {
    OutlinedButton,
    Button
};