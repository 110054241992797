import { ReactNode, useEffect } from "react";
import donation from '../../static/assets/donation.jpg';

type Props = {
    children?: ReactNode
    className?: string
}

const Section = ({children}: Props) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-12 gap-x-5 md:pt-8 pb-8 bg-[#FAFAFA]">
            {children}
        </div>
    )
}

const About = () => {

    useEffect(() => {
        document.title = 'about us | stay smiling co'
    }, []);

    return (
        <>
            <Section>
                <div className="md:col-start-2 md:col-end-5 flex flex-col justify-center items-center">
                    <h1 className="text-6xl font-bold">about us</h1>
                </div>
                <img src={donation} className="md:col-start-6 md:col-end-13 md:h-[600px] lg:h-[700px] object-cover"/>
            </Section>
            <Section>
                <p className="md:col-start-2 md:col-end-12 pt-[120px] pb-[120px] font-extralight leading-normal text-3xl">
                    We are on a quest to redefine fashion as a tool. Wear our garments, embody our ethos, and embark on the journey towards the greatness that awaits you.
                </p>
            </Section>
            <Section>
            <img className="col-start-1 col-end-13 md:col-end-8 h-[600px] object-cover" src={donation}/>
                <div className="col-start-2 col-end-12 md:col-start-9 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl pb-2 pt-1">rooted in commitment</h2>
                    <p className="text-xl pb-2 pt-1">
                        We're committed to shaping a better future through today's actions. 
                        While each day may not be perfect, we remain steadfast in our vision, believing that the future we strive for is worth every effort - smiling or not.
                    </p>
                </div>
            </Section>
            <Section>
                <img className="col-start-1 col-end-13 md:col-end-8 h-[600px] object-cover" src={donation}/>
                <div className="col-start-2 col-end-12 md:col-start-9 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl pb-2 pt-1">community-inspired</h2>
                    <p className="text-xl pb-2 pt-1">
                        Our mission extends beyond our clothing. 
                        It’s rooted in our community and the drive to support everyday people who seek more from life. 
                        We’re dedicated to partnering with those who have goals—whether it’s running a race, becoming a professional athlete, or starting a business. 
                        By sharing your journey, we aim to inspire others to pursue their own future.
                    </p>
                </div>
            </Section>
            <Section>
            <img className="col-start-1 col-end-13 md:col-end-8 h-[600px] object-cover" src={donation}/>
                <div className="col-start-2 col-end-12 md:col-start-9 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl pb-2 pt-1">built for anything</h2>
                    <p className="text-xl pb-2 pt-1">
                        Our clothing is built for every day and any occasion. 
                        With a focus on comfort, versatility, and timeless design, we ensure you look and feel confident, whether you’re on the go, at work, or out on the town.
                    </p>
                </div>
            </Section>
        </>
    )
}

export { About }
