import styled from 'styled-components';


const Container = styled.div`
    text-align: center;
    padding-top: 10rem;
    padding-bottom: 16rem;  
`

export const NotFound = () => {
    return (
        <Container>
            <h1 className='font-semibold text-2xl'> Page not found </h1>
        </Container>
    )
}