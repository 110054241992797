import { useEffect } from "react";
import styled from "styled-components";

const Content = styled.div`
    grid-column-start: 2;
    grid-column-end: 12;
    font-size: 16px;
    line-height: 1.4;
`;


const Contact = () => {

    useEffect(() => {
        document.title = 'contact | stay smiling co'
    }, []);

    return (
        <>
            <div className="bg-[#FAFAFA] grid grid-cols-12 pt-16 pb-16">
                <div className="col-start-2 col-end-12">
                    <h1 className="text-3xl font-semibold mb-4">Contact Us</h1>
                    <p className="pt-4 pb-4">
                        We're so thrilled that you want to reach out to us. To contact us, please reach out to
                    </p>
                    <a className="text-black pt-4 pb-4" href="mailto:staysmilingcompany@gmail.com">staysmilingcompany@gmail.com</a>
                    <p className="pt-4 pb-4">We will get back to you as soon as possible. Stay smiling!</p>
                </div>
            </div>
        </>
    )
}

export {
    Contact
}