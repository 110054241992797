import { useEffect } from "react";
import styled from 'styled-components';
import island from '../../static/assets/island.png';

const Jumbotron = styled.div`
    background: url(${island}) no-repeat;
    background-size: cover;
`;

const Home = () => {

    useEffect(() => {
        document.title = 'home | stay smiling co'
    }, []);

    return (
        <>
            <div className="grid bg-[#FAFAFA] grid-cols-12 gap-x-5">
                <div className="col-start-1 col-end-13 text-center">
                    <p className="font-light pt-4 pb-4 text-sm">STORE COMING SOON</p>
                </div>
                <Jumbotron className="col-start-1 col-end-13 relative lg:h-[800px] md:h-[600px] sm:h-[500px] h-[400px] grid grid-cols-1 md:grid-cols-12 grid-rows-6">
                    <div className="col-start-1 md:col-start-2 md:col-end-12 row-start-3 text-center">
                        <h1 className="relative font-semibold text-white tracking-wide text-6xl before:absolute before:content-[''] before:h-[6px] before:w-[64px] before:bg-white before:top-2">uplift urself</h1>
                    </div>
                </Jumbotron>
            </div>
        </>
    )
}

export { Home }