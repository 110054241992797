import logo from '../../static/assets/logos/solid-red.svg';
import styled from 'styled-components';

import { useState } from 'react';

type NavbarProps = {
    backgroundColor?: string;
    textColor?: string;
    logo?: any;
}

const Nav = styled.nav`
    width: 100%;
    background-color: white;
    position: fixed;
    border-bottom: 1px solid #e7e7e7;
    z-index: 2;
`;

const Content = styled.div`
    max-width: 1440px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    padding: 1.25rem 0;
`;

const Centered = styled.div`
    grid-column: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const LogoContainer = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
`;

const Logo = styled.img`
    width: 40px;
    height: 40px;
    margin-right: .5rem;
`;

export const Navbar = (props: NavbarProps) => {

    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

    return (
        <Nav className="navbar" style={{backgroundColor: props.backgroundColor}}>
            <Content>
                <Centered>
                    <LogoContainer href = "/" className = "logo-container">
                        <Logo src={props.logo || logo} alt="logo" className="logo"/>
                    </LogoContainer>
                </Centered>
            </Content>
        </Nav>
    )
}