import { useEffect } from "react";
import { Paths } from "../../constants/paths";


export const QrCode = () => {

    useEffect(() => {
        window.location.href = `${Paths.ABOUT}?qrCode=true`;
    }, []);

    return null;
}