import './App.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Navbar } from './components/navbar/Navbar';
import { NotFound } from './features/not-found/NotFound';
import { QrCode } from './features/qr-code/QrCode';
import { Paths } from './constants/paths';
import { Home } from './features/home/Home';
import { About } from './features/about/About';
import { Footer } from './components/footer/Footer';
import { useEffect } from 'react';
import { Contact } from './features/contact/Contact';

import * as featureFlags from './static/data/flags';

interface RouteEntry {
  path: string;
  name?: string;
  element: JSX.Element;
  enabled: boolean;
};

function App() {

  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  const routeTable: RouteEntry[] = [
    {
      path: Paths.NONE,
      element: <Home/>,
      name: 'Home',
      enabled: true
    },
    {
      path: Paths.HOME,
      element: <Home/>,
      name: 'Home',
      enabled: true
    },
    {
      path: Paths.ABOUT,
      element: <About/>,
      name: 'About Us',
      enabled: featureFlags.aboutFeature
    },
    {
      path: Paths.CONTACT,
      element: <Contact/>,
      name: 'Contact Us',
      enabled: featureFlags.contactFeature
    },
    {
      path: Paths.QRCODE,
      element: <QrCode/>,
      enabled: true
    },
    {
      path: Paths.OTHER,
      element: <NotFound/>,
      enabled: true
    }
  ]

  return (
    <div className="app">
      <Navbar/>
      <div className="pt-[80px]"/>
        <Routes>
          {
            routeTable.map((route: RouteEntry) => {
              return route.enabled ? <Route path={route.path} element={route.element}/> : null;
            })
          }
        </Routes>
      <Footer corporatePaths={routeTable.filter((route) => route.enabled && [Paths.ABOUT, Paths.CONTACT].includes(route.path)).map((route) => [route.path, route.name ?? ''])}/>
    </div>
  );
}

export default App;
