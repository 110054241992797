import { Link as RouterLink } from "react-router-dom";
import { Paths } from "../../constants/paths";
import { useState } from "react";
import { Button } from "../button/Button";


interface FooterProps {
    corporatePaths: string[][];
}

export const Footer = (props: FooterProps) => {

    const [email, setEmail] = useState<string>("");

    return (
    <>
        <div className="pt-16 pb-16 grid grid-cols-12 gap-x-5">
            <div className="row-start-auto col-start-2 col-end-12 md:col-end-6 pb-4 mb-4 md:pb-0 md:mb-0">
                <p className="font-semibold text-2xl mb-4">Want to stay up to date with us?</p>
                <p className="leading-normal">Get a dose of positivity in your life and stay up to date with everything we’re doing! Sign up for our newsletter below</p>
                <form action="https://staysmilingco.us21.list-manage.com/subscribe/post?u=5f9989e5493fe484e4117a869&amp;id=89abe597d9&amp;f_id=00a1eae6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
                    <div>
                        <input className="mt-4 mb-8 pb-2 border-b-[1px] border-b-[#E7E7E7] focus:border-b-black focus:outline-none w-[100%]" type="email" name="EMAIL" id="mce-EMAIL" required={true} value={email} onChange={(e: any) => setEmail(e.target.value)} placeholder='Email Address'/>
                    </div>
                    <Button type="submit" name="subscribe" id="mc-embedded-subscribe">Sign Up</Button>
            
                    <div className="absolute left-[-5000px]" aria-hidden="true">
                        <input type="text" name="b_eb05e4f830c2a04be30171b01_8281a64779" tabIndex={-1} value=""/>
                    </div>
                </form>
            </div>
            <div className="row-start-auto col-start-2 col-end-12 md:col-start-8 md:col-end-9">
                <p className="font-semibold text-l mb-4">Corporate</p>
                <ul className="list-none m-0 p-0">
                    {
                    props.corporatePaths
                        .map(([to, title]) => (
                        <li className="p-0 m-0 pb-4">
                            <RouterLink className="text-[#53565A] no-underline hover:text-black" to={to}>{title}</RouterLink>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="row-start-auto col-start-2 col-end-12 md:col-start-10 md:col-end-11">
                <p className="font-semibold text-l mb-4">Socials</p>
                <ul className="list-none m-0 p-0">
                    {[
                        ['Instagram', 'https://www.instagram.com/staysmilingco/']
                    ].map(([title, to]) => (
                        <li className="p-0 m-0 pb-4">
                            <RouterLink className="text-[#53565A] no-underline hover:text-black" to={to} target="_blank">{title}</RouterLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </>
    )
}